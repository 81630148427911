import { darken } from "@mui/material";

import { designTokens } from "@/designTokens";
import { createTheme, Theme } from "./theme";

export const componentTheme = (theme: Theme) => {
  return createTheme({
    ...theme,
    components: {
      MuiAlert: {
        styleOverrides: {
          message: designTokens.typography.copySmall,
          root: {
            display: "flex",
            alignItems: "center",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          colorPrimary: {
            "&:active": {
              color: theme.palette.secondary.main,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: designTokens.typography.copyLarge,
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          root: {
            borderRadius: 0,
            background: darken(theme.palette.background.default, 0.025),

            "&:hover": {
              background: darken(theme.palette.background.default, 0.05),
            },

            "&.Mui-focused": {
              background: darken(theme.palette.background.default, 0.075),
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: 48,
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: designTokens.typography.copyLargeHero,
        },
      },
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            "&>tr>td": {
              border: "none",
            },
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:focus-visible": {
              border: `solid 1px ${theme.palette.secondary.main}`,
              background: theme.palette.secondary.main,
              boxShadow: `inset 0px 0px 0px 2px white`,
              ">svg": {
                fill: theme.palette.secondary.main,
                stroke: "white",
              },
            },
          },
          colorInherit: {
            "&:focus-visible": {
              border: `solid 1px ${theme.palette.primary.main}`,
              background: theme.palette.secondary.main,
              boxShadow: `inset 0px 0px 0px 2px white`,
              ">svg": {
                stroke: "white",
              },
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: 18,
          },
          textSizeLarge: {
            borderRadius: designTokens.button.sizes.large.height / 2,
            height: designTokens.button.sizes.large.height,
          },
          textSizeMedium: {
            borderRadius: designTokens.button.sizes.medium.height / 2,
            height: designTokens.button.sizes.medium.height,
          },
          textSizeSmall: {
            borderRadius: designTokens.button.sizes.small.height / 2,
            height: designTokens.button.sizes.small.height,
          },
          text: {
            padding: "0 24px",

            "&:focus-visible": {
              // border: `solid 1px ${theme.palette.secondary.light}`,
              // background: theme.palette.secondary.main,
              // boxShadow: `inset 0px 0px 0px 2px white`,
              // color: "white",
            },
          },
          containedSizeLarge: {
            borderRadius: designTokens.button.sizes.large.height / 2,
            height: designTokens.button.sizes.large.height,
          },
          containedSizeMedium: {
            borderRadius: designTokens.button.sizes.medium.height / 2,
            height: designTokens.button.sizes.medium.height,
          },
          containedSizeSmall: {
            borderRadius: designTokens.button.sizes.small.height / 2,
            height: designTokens.button.sizes.small.height,
          },
          containedSecondary: {
            transition: "all 0.25s linear",

            "&:focus-visible": {
              border: `solid 1px ${theme.palette.secondary.light}`,
              background: theme.palette.secondary.main,
              boxShadow: `inset 0px 0px 0px 2px white`,
            },

            "&:hover": {
              background: theme.palette.secondary.main,
              border: `none`,
              boxShadow: "none",
            },

            "&:active": {
              background: theme.palette.secondary.dark,
              border: `none`,
              boxShadow: "none",
            },
          },
          outlinedSizeLarge: {
            borderRadius: designTokens.button.sizes.large.height / 2,
            height: designTokens.button.sizes.large.height,
          },
          outlinedSizeMedium: {
            borderRadius: designTokens.button.sizes.medium.height / 2,
            height: designTokens.button.sizes.medium.height,
          },
          outlinedSizeSmall: {
            borderRadius: designTokens.button.sizes.small.height / 2,
            height: designTokens.button.sizes.small.height,
          },
          outlinedPrimary: {
            transition: "all 0.25s linear",
            border: `solid 2px ${theme.palette.primary.main}`,

            "&:focus-visible": {
              border: `solid 1px ${theme.palette.primary.main}`,
              background: theme.palette.secondary.main,
              boxShadow: `inset 0px 0px 0px 2px white`,
              color: "white",
            },

            "&:hover": {
              border: `solid 2px ${theme.palette.secondary.main}`,
              background: "transparent",
              boxShadow: "none",
              color: `${theme.palette.secondary.main}`,
            },

            "&:active": {
              border: `solid 2px ${theme.palette.secondary.dark}`,
              background: "transparent",
              boxShadow: "none",
              color: `${theme.palette.secondary.dark}`,
            },
          },
        },
      },
    },
  });
};

export default componentTheme;
