export const dashboard = {
  error: {
    report: {
      title: "Report error",
      description:
        "Something went wrong and the application can't continue. Sorry for the inconvenience!",
      yourHelp: "Do you want to help us out?",
      yourHelpInfo:
        "If you have some information, we can find the error faster and can improve the whole thing.",
      textfieldLabel: "Your feedback",
      textfieldHelperText: "What did you do when the error occurred? Or what could we do to help?",
      send: "Send",
    },
    details: "Details for questions",
    codes: {
      NOT_FOUND: "Fehler",
      INTERNAL_SERVER_ERROR: "Internal server error",
      BAD_REQUEST: "Bad request",
      PROTOCOL_ERROR: "Protocol error",
      NETWORK_ERROR: "Network error",
    },
  },
  general: {
    preview: "New view",
    email: "Email address",
    save: "Save",
    ok: "Ok",
    cancel: "Cancel",
    language: {
      de: "German",
      en: "English",
    },
    roles: {
      admin: "Admin",
      owner: "Owner",
      visitor: "Visitor",
      security: "Team Lead",
      securityTeam: "Team Support",
      awareness: "Team Lead",
      awarenessTeam: "Team Support",
      default: "Viewer",
    },
    situation: {
      description: {
        unknown: "There is no further information.",
        harassed: "Someone is being harassed.",
        attacked: "Someone is being attacked.",
        somethingWrong: "Something is wrong.",
        emergency: "There is an emergency.",
        notify: "Someone wants to notify you.",
        discriminated: "Someone is being discriminated against.",
      },
    },
    person: {
      description: {
        unknown: "The person is unknown.",
        victim: "The person is the victim.",
        witness: "The person is a witness.",
        staff: "The person is a staff member.",
      },
    },
    error: {
      generic: "Something went wrong.",
      loginRequired: "Please login first.",
    },
    form: {
      save: "Save",
    },
    booking: {
      free: "Free",
      payment: "One-time",
      subscription: "Recurring",
    },
    humanTime: {
      justNow: "Just now",
      oneMinuteAgo: "A minute ago",
      minutesAgo: "{{minutes}} minutes ago",
      anHourAgo: "An hour ago",
      hoursAgo: "{{hours}} hours ago",
      oneDayAgo: "A day ago",
      daysAgo: "{{days}} days ago",
      aWeekAgo: "A week ago",
      weeksAgo: "{{weeks}} weeks ago",
    },
  },
  barrier: {
    premium: {
      lock: "This feature is only available in the premium version of saferspaces.",
    },
  },
  admin: {
    pages: {
      archive: {
        resetVenueArchived: "Reactviate",
      },
      map: {
        title: "Map",
      },
      projects: {
        title: "Projects",
        name: "Name",
        addManualBooking: "Manual booking",
        manualBookingModal: {
          bookingDate: "Booking period",
          emptyBookingDate: "There is currently no period set in which this event takes place.",
        },
        createManualSubscription: "Create",
        createManualPayment: "Create",
        setVenueArchived: "Archivieren",
        archive: {
          title: "Archiviert",
        },
        overview: {
          title: "Overview",
        },
        map: {
          title: "Map",
        },
        print: {
          title: "Print",
        },
      },
      statistics: {
        menu: "Statistics",
        lastYear: "Last year",
        last28Days: "Last month",
        lastWeek: "Last week",
        last24Hours: "Last day",
        location: "Location",
        situation: "Situation",
        name: "Name",
      },
      events: {
        menu: "Logs",
      },
      booking: {
        title: "Bookings",
        name: "Name",
        type: "Type",
        amount: "Amount",
        status: "Status",
        date: "From - to",
      },
      user: {
        menu: "User",
        name: "Name",
        email: "Email address",
        lastRefresh: "Last login",
        createdAt: "Registered at",
        lastActivity: "Last activity",
      },
    },
  },
  anonymous: {
    pages: {
      barrier: {
        hello: "Hello!",
        hint: "The administration of saferspaces is currently not optimized for small screen sizes. Please switch to a laptop/desktop PC to set up the QR Codes or contact us at <1>kontakt@saferspaces.io</1> if you have any further questions.",
      },
    },
  },
  user: {
    pages: {
      booking: {
        active: "Active",
        amount: "Amount",
        cancel: "Cancel",
        canceled: "Canceled",
        endsAt: "Ends at",
        inactive: "Inactive",
        menu: "Bookings",
        name: "Name",
        startsAt: "Starts at",
        payment: {
          title: "Payment",
          cancel: "Cancel",
          canceled: "Canceled",
          cancelConfirmationTitle: "Cancel payment",
          cancelConfirmationText: "Are you sure that you want to cancel the payment?",
          cancelConfirmation: "Cancel",
          edit: "Edit Payment Method",
          editPaymentMethodTitle: "Edit Payment Method",
          editPaymentMethodText:
            "Have your card details changed? You can use the following link to change your payment method at any time.",
          editPaymentMethod: "To payment provider",
        },
        subscription: {
          title: "Subscription",
          cancel: "Cancel",
          canceled: "Canceled",
          cancelConfirmationTitle: "Cancel subscription",
          cancelConfirmationText: "Are you sure that you want to cancel the subscription?",
          cancelConfirmation: "Cancel",
          edit: "Edit Payment Method",
          editPaymentMethodTitle: "Edit Payment Method",
          editPaymentMethodText:
            "Have your card details changed? You can use the following link to change your payment method at any time.",
          editPaymentMethod: "To payment provider",
        },
        free: {
          title: "Test access",
          cancel: "cancel",
          canceled: "canceled",
          cancelConfirmationTitle: "cancelConfirmationTitle",
          cancelConfirmationText: "cancelConfirmationText",
          cancelConfirmation: "cancelConfirmation",
          edit: "edit",
          editPaymentMethodTitle: "editPaymentMethodTitle",
          editPaymentMethodText: "editPaymentMethodText",
          editPaymentMethod: "editPaymentMethod",
        },
        type: "Type",
      },
      projects: {
        agbInfo:
          "You can test saferspaces in a limited scope for two weeks free of charge. If you want to use our application beyond that, you can make a booking.",
        agb: "Hereby i confirm having read the current <1>terms and conditions</l>.",
        agbNext: "Book now",
        archivedAt: "Archived",
        prices: "Hereby i confirm having noticed the current <1>prices</l>.",
        activateProjectMonthlyCosts:
          "There are monthly costs of {{costs}} for a max capacity of {{people}} visitors",
        activateProjectMonthlyCostsPre: "Monthly costs in the amount of",
        activateProjectMonthlyCostsPost:
          "for the specified maximum number of {{people}} visitors will be incurred.",
        activateEventCosts:
          "There are one-off costs of {{costs}} for a max capacity of {{people}} visitors",
        activateEventSubmit: "Pay now",
        activateProject: "Upgrade",
        activateProjectError: "There was an error with the booking. Please contact us directly.",
        activateProjectSubmit: "Pay now",
        activateSubscription: "Upgrade now",
        activateSubscriptionInfo: "There are monthly costs of",
        onBoardingPrice:
          "Additionally, only for your first booking, one-time service costs of {{costs}} are charged separately.",
        changeImage: "Change image",
        changeName: "Rename",
        createProject: "Create",
        menu: "Projects",
        name: "Name",
        newProject: "New project",
        newProjectError: "An error occured",
        next: "Next",
        noProject: "Welcome to your saferspaces administration!",
        noProjectEnd: "Your saferspaces team",
        noProjectText:
          "Here you can create and manage your projects. A project is always the context in which you will use or offer saferspaces. Your club, your festival, the stadium or city festival, etc. Create your first project in the first step, then you can get started right away and generate the appropriate codes for the different locations. In the administration you will also find some individual settings for the optimal use of saferspaces for your context. Feel free to look around! If you have any questions, please feel free to contact us at any time.",
        notVerified: "Test account",
        renameImageError: "There was an error while trying to save the new url",
        renameProject: "Rename project",
        renameProjectError: "There was an error while trying to save the new name",
        editProjectDetails: "Edit",
        role: "Role",
        skip: "Free trial",
        submit: "Save",
        title: "Your projects",
        updated: "Updated",
        venueImage: "URL",
        verify: "Accept",
        subscription: {
          error: "Error",
          active: "Active",
          inactive: "Inactive",
          finished: "Finished",
          trialPeriod: "Test account",
          startsIn_zero: "Starts today",
          startsIn_one: "Starts tomorrow",
          startsIn_other: "Starts in {{count}} days",
          startsInMore: "Starts in more than 4 weeks",
          endsIn_zero: "Ends today",
          endsIn_one: "Ends tomorrow",
          endsIn_other: "Ends in {{count}} days",
          endsInMore: "Ends in more than 4 weeks",
          trialEndsIn_zero: "Free trial period ends today",
          trialEndsIn_one: "Free trial period ends tomorrow",
          trialEndsIn_other: "Free trial period ends in {{count}} days",
          freeTrialEndsIn_zero: "Free trial period ends today",
          freeTrialEndsIn_one: "Free trial period ends tomorrow",
          freeTrialEndsIn_other: "Free trial period ends in {{count}} days",
        },
        addProject: {
          name: "Name",
          type: "Type",
          weeklyOpen: "Open regularly",
          capacity: "Capacity",
          capacityEndAdornment: "Visitors",
          startsAt: "From",
          endsAt: "To",
        },
        ownerOnly: "This function is currently only available for users with the role 'Owner'",
      },
      profile: {
        title: "Your profile",
        menu: "Profile",
        settings: "Settings",
        phoneNumber: "Phone number",
        name: "Name",
        language: "Language",
      },
      payments: {
        menu: "Payments",
      },
    },
  },
  venue: {
    name: "Name",
    actions: {
      archive: "Archive",
      edit: "Edit",
      bookNow: "Book now",
      navigateToProject: "Go to project",
    },
    edit: {
      title: "Edit",
      name: "Name",
      image: "Image",
      save: "Save",
      errors: {
        name: "Please enter a name",
        image: "Please enter a valid URL",
      },
    },
    type: {
      club: "Club",
      bar: "Bar",
      event: "Event",
      festival: "Festival",
      other: "Other",
      stadium: "Stadium",
    },
    pages: {
      admin: { title: "Admin" },
      locations: {
        title: "Locations",
      },
      settings: {
        title: "Settings",
      },
      team: {
        title: "Team",
      },
      print: {
        title: "Print",
      },
      activity: {
        title: "Activity",
      },
      bookings: {
        title: "Bookings",
      },
    },
  },
} as const;
