import { useEffect, useRef, useState } from "react";

import { Caption, CopyLarge } from "@/system/atoms/Typography";
import { Div, FlexBox } from "@/system/atoms/Components";

import { clampPercent, cmykToHex } from "./utils";
import Input from "../../pages/(private)/venue/print/_components/Input";
import { styled } from "@/system/theme";

export type CMYKColor = {
  cyan: number;
  magenta: number;
  yellow: number;
  key: number;
};

const CMYKColorPicker = (props: {
  disabled?: boolean;
  label: string;
  color: CMYKColor | null;
  onChange: (color: CMYKColor | null) => void;
}) => {
  return (
    <FlexBox justifyContent="space-between" alignItems="center">
      <FlexBox alignItems="center">
        <Div
          elevation={props.disabled ? undefined : 6}
          mr={1}
          p={2}
          background={
            props.disabled || props.color === null
              ? "rgba(0, 0, 0, 0.05)"
              : props.color
              ? cmykToHex(props.color)
              : undefined
          }
          borderRadius={2}
          cursor={props.disabled ? undefined : "pointer"}
          onClick={() => {
            if (!props.disabled) {
              props.onChange(props.color ? null : { cyan: 0, magenta: 0, yellow: 0, key: 0 });
            }
          }}
        />
        <CopyLarge>{props.label}</CopyLarge>
      </FlexBox>

      <FlexBox alignItems="center">
        <Div mr={1}>
          <Caption>CMYK</Caption>
        </Div>

        <CMYKNumberSelect
          key={JSON.stringify(props.color)}
          color={props.color}
          onChange={props.onChange}
          disabled={props.disabled || props.color === null}
        />
      </FlexBox>
    </FlexBox>
  );
};

const colors: ["cyan", "magenta", "yellow", "key"] = ["cyan", "magenta", "yellow", "key"];

const CMYKNumberSelect = (props: {
  color: CMYKColor | null;
  onChange: (color: CMYKColor | null) => void;
  disabled?: boolean;
}) => {
  const [color, setColor] = useState(props.color);
  const [hasFocus, setHasFocus] = useState<boolean>(false);
  const mountedRef = useRef(false);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (mountedRef.current && !hasFocus) {
      timeout = setTimeout(() => {
        props.onChange(color);
      }, 1);
    }

    mountedRef.current = true;

    return () => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [color, hasFocus]);

  return (
    <CMYKNumberSelectFlexBox component="div">
      {colors.map((current) => {
        return (
          <Input
            key={current}
            disabled={props.disabled}
            maxLength={3}
            onFocus={() => {
              setHasFocus(true);
            }}
            onBlur={() => {
              setHasFocus(false);
            }}
            onChange={(event) => {
              const value = clampPercent(Number(event.target.value));

              setColor((color) => {
                return {
                  cyan: color?.cyan ?? 0,
                  magenta: color?.magenta ?? 0,
                  yellow: color?.yellow ?? 0,
                  key: color?.key ?? 0,
                  [current]: value,
                };
              });
            }}
            value={color?.[current]}
          />
        );
      })}
    </CMYKNumberSelectFlexBox>
  );
};

export const CMYKNumberSelectFlexBox = styled(FlexBox)(({ theme }) => ({
  "& input": {
    width: 24,
    height: 22,
  },

  "& :first-child": {
    padding: "12px 8px 12px 12px",
    borderTopLeftRadius: theme.spacing(3),
    borderBottomLeftRadius: theme.spacing(3),
  },

  "& :not(:first-child)": {
    borderLeft: "none",
  },

  "& :last-child": {
    padding: "12px 12px 12px 8px",
    borderTopRightRadius: theme.spacing(3),
    borderBottomRightRadius: theme.spacing(3),
  },
}));

export const CMYKColorPickerSliderNumberFlexBox = styled(FlexBox)(({ theme }) => ({
  display: "flex",
  margin: theme.spacing(2, 0),

  "& input": {
    width: 1,
    flex: 1,
  },
}));

export const CMYKColorPickerSliderFlexBox = styled(FlexBox)(() => ({
  display: "flex",

  "&>:first-child": {
    width: "32px",
  },

  "&>:last-child": {
    flex: 1,
  },
}));

export default CMYKColorPicker;
